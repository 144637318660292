import React, { FC } from 'react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import { getTypeDescription, useNumberedNode } from '../../numbering';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import styled from '@emotion/styled';
import { BlockNode } from '../BlockNode';
import { Dataset, DatasetLinked, Delete } from '@mui/icons-material';
import { useModal } from '../../../../../dialogs/useModal';
import { SearchDialog, SearchDialogProps, SearchDialogResult } from '../../../../../dataSources/SearchDialog';

const StyledTableFigure = styled.figure`
  & > * > * > figcaption {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #bdbdbd;
    width: fit-content;
    min-width: 400px;

    &:before {
      font-size: 12px;
      color: #666;
      position: absolute;
      top: -10px;
      background-color: white;
    }
    &:nth-of-type(1):before {
      content: 'Tabelltekst';
    }
    &:nth-of-type(2):before {
      content: 'Fotnote';
    }
  }
`;

export const TableFigureView: FC<NodeViewProps> = (props) => {
  const modal = useModal<SearchDialogProps, SearchDialogResult>({ data: {} });
  const attrs = props.node.attrs;
  const isConnectedToDataSource = attrs.dataSourceId !== undefined;

  const handleOnClick = async () => {
    const payload = await modal.open({
      dataSourceId: attrs.dataSourceId,
      columns: attrs.columns,
      filter: attrs.filter ? JSON.parse(attrs.filter) : undefined,
    });
    if (payload) {
      replaceTable(payload, props);
    }
  };

  const node = useNumberedNode(attrs.id);
  return (
    <NodeViewWrapper className="react-component">
      {modal.isOpen && <SearchDialog modal={modal} />}
      <BlockNode props={props}>
        <StyledTableFigure id={`tab${node?.numbering}`}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption">
              {capitalizeFirstLetter(getTypeDescription('tableFigure'))} {node?.numbering}
            </Typography>
            {props.editor.isEditable && (
              <div>
                <ButtonGroup orientation="horizontal">
                  <IconButton title={isConnectedToDataSource ? 'Oppdater fra datakilde' : 'Hent fra datakilde'} onClick={handleOnClick}>
                    {isConnectedToDataSource ? <DatasetLinked /> : <Dataset />}
                  </IconButton>
                  <IconButton title="Slett tabell" onClick={() => props.deleteNode()}>
                    <Delete />
                  </IconButton>
                </ButtonGroup>
              </div>
            )}
          </Stack>
          <NodeViewContent className="content" as="div"></NodeViewContent>
        </StyledTableFigure>
      </BlockNode>
    </NodeViewWrapper>
  );
};

const replaceTable = (payload: SearchDialogResult, props: NodeViewProps) => {
  const table = {
    type: 'table',
    content: [] as any[],
  };

  const headerRow = {
    type: 'tableRow',
    content: [] as any[],
  };

  for (let i = 0; i < payload.columns.length; i++) {
    const cell = {
      type: 'tableHeader',
      attrs: { colspan: 1, rowspan: 1, colwidth: [] },
      content: [{ type: 'paragraph', content: [{ type: 'text', text: payload.columns[i].title }] }],
    };
    headerRow.content.push(cell);
  }
  table.content.push(headerRow);

  for (let i = 0; i < payload.values.length; i++) {
    const row = {
      type: 'tableRow',
      content: [] as any[],
    };

    for (let j = 0; j < payload.columns.length; j++) {
      const cell = {
        type: 'tableCell',
        attrs: { colspan: 1, rowspan: 1, colwidth: [] },
        content: [{ type: 'paragraph', content: payload.values[i][j].value ? [{ type: 'text', text: payload.values[i][j].value }] : [] }],
      };

      row.content.push(cell);
    }

    table.content.push(row);
  }

  const tableFigure = {
    type: 'tableFigure',
    attrs: {
      id: props.node.attrs.id,
      dataSourceId: payload.dataSourceId,
      version: payload.version,
      dataSourceCorrelationId: payload.dataSourceCorrelationId,
      columns: payload.columns?.map((c) => c.id),
      filter: payload.filter ? JSON.stringify(payload.filter) : undefined,
    },
    content: [
      { type: 'figcaption', content: [{ type: 'paragraph', content: [] }] },
      table,
      { type: 'figcaption', content: [{ type: 'paragraph', content: [] }] },
    ],
  };

  props.editor?.chain().focus().setNodeSelection(props.getPos()).insertContent(tableFigure).scrollIntoView().run();
};
