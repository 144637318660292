import React, { FC } from 'react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import { getTypeDescription, useNumberedNode } from '../../numbering';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import styled from '@emotion/styled';
import { BlockNode } from '../BlockNode';
import { Delete } from '@mui/icons-material';

const StyledChartFigure = styled.figure`
  & > * > * > figcaption {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #bdbdbd;
    width: fit-content;
    min-width: 400px;

    &:before {
      font-size: 12px;
      color: #666;
      position: absolute;
      top: -10px;
      background-color: white;
      content: 'Figurtekst';
    }
  }
`;

export const ChartFigureView: FC<NodeViewProps> = (props) => {
  const attrs = props.node.attrs;
  const node = useNumberedNode(attrs.id);
  return (
    <NodeViewWrapper className="react-component">
      <BlockNode props={props}>
        <StyledChartFigure id={`chart${node?.numbering}`}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption" style={{ width: '48px' }}>
              {capitalizeFirstLetter(getTypeDescription('chartFigure'))} {node?.numbering}
            </Typography>
            {props.editor.isEditable && (
              <div>
                <ButtonGroup orientation="horizontal">
                  {/* Make room for edit button from inside ChartView */}
                  <div style={{ height: '40px', width: '40px' }}></div>
                  <IconButton title="Slett graf" onClick={() => props.deleteNode()}>
                    <Delete />
                  </IconButton>
                </ButtonGroup>
              </div>
            )}
          </Stack>
          <NodeViewContent className="content" as="div"></NodeViewContent>
        </StyledChartFigure>
      </BlockNode>
    </NodeViewWrapper>
  );
};
