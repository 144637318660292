import React, { ElementType, FC } from 'react';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import styled from '@emotion/styled';
import { useNumberedNode } from '../../numbering';
import { BlockNode } from '../BlockNode';

const StyledNodeViewContent = styled(NodeViewContent)<{ numbering: string }>`
  & > :before {
    content: '${({ numbering }) => numbering} ';
  }
`;

export const HeadingView: FC<NodeViewProps> = (props) => {
  const node = useNumberedNode(props.node.attrs.id);

  const asComponent = `h${props.node.attrs.level}` as ElementType;
  return (
    <NodeViewWrapper className="react-component" id={`i${node?.numbering}`}>
      <BlockNode props={props}>
        <StyledNodeViewContent as={asComponent} numbering={node?.numbering ?? ''}></StyledNodeViewContent>
      </BlockNode>
    </NodeViewWrapper>
  );
};
