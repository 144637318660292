import { SxProps, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { FC } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { Forum, Help, Toc, ViewSidebar } from '@mui/icons-material';
import styled from '@emotion/styled';

const StyledSidebarButtons = styled.div`
  padding-right: 8px;
`;

const toggleButtonStyle: SxProps<Theme> = {
  backgroundColor: '#FFF',
};

export const SidebarButtons: FC = () => {
  const { sidebarType, setSidebarType, isSidebarOpen, toggleSidebar } = useGuideCmsContext();
  return (
    <StyledSidebarButtons>
      <div>
        <ToggleButton
          title={isSidebarOpen ? 'Skjul sidemeny' : 'Vis sidemeny'}
          sx={{ marginBottom: 2, ...toggleButtonStyle }}
          size="small"
          value=""
          selected={isSidebarOpen}
          onChange={() => toggleSidebar()}>
          <ViewSidebar fontSize="small" />
        </ToggleButton>
      </div>

      <ToggleButtonGroup
        orientation="vertical"
        value={sidebarType}
        exclusive
        onChange={(_e, type) => {
          if (!isSidebarOpen) {
            toggleSidebar();
          }
          if (type !== null && sidebarType !== type) {
            setSidebarType(type);
          }
        }}>
        <ToggleButton title="Innholdsfortegnelse" size="small" value="toc" selected={isSidebarOpen && sidebarType === 'toc'} sx={toggleButtonStyle}>
          <Toc fontSize="small" />
        </ToggleButton>
        <ToggleButton title="Kommentarer" size="small" value="comments" selected={isSidebarOpen && sidebarType === 'comments'} sx={toggleButtonStyle}>
          <Forum fontSize="small" />
        </ToggleButton>
        <ToggleButton title="Hjelp" size="small" value="help" selected={isSidebarOpen && sidebarType === 'help'} sx={toggleButtonStyle}>
          <Help fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledSidebarButtons>
  );
};
